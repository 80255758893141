.read-me-container {
  padding: 20px;
  margin-top: 3vh;
  background-color: rgb(0, 0, 0, 0.4);
  border-radius: 5px;
  color: #dedede;
  font-size: 1.2rem;
  width: 95%;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.read-me-header {
  font-size: 2rem;
  font-weight: bold;

}

.read-me-subheading {
  font-size: 1.5rem;
  font-weight: bold;
}

.read-me-text {
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.7)
}

.read-me-list-item {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.7);
}

.read-me-list-item-title {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9);
}

.code {
  font-family: monospace;
  font-weight: 300;
  font-size: 1rem;
  background-color: rgba(52, 52, 52, 0.3) !important;
  padding: 3px;
  border-radius: 3px;
  margin-right: 2px;
}

.read-me-list {
  margin-top: 10px;
  margin-left: 30px;
}

@media (max-width: 768px) {
  .read-me-container {
    min-width: 60%;
    width: 90%;
    margin-left: 0;
    margin-top: 20px;
  }
}
